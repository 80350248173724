// @flow

import type {ApplicationState} from "../reducers";
import type {CartProps} from "../components/Cart";
import type {Dispatch} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import Search from "../components/Search";
import {customHistory} from "../helper/history";

function mapStateToProps(state: ApplicationState, ownProps: CartProps) {
	return {
		searchPhrase: state.filter.fuzzy,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CartProps) {
	return {
		setSearch: fuzzy => customHistory.push("/search/" + fuzzy)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Search));
