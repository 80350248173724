// @flow
import React, {useEffect, useRef, useState} from "react";
import {Route, Router, Switch} from "react-router";
import StartPageContainer from "../container/StartPageContainer";
import Loading from "./Loading";
import "./App.scss";
import "../assets/styling/css/oem.scss";
import {customHistory} from "../helper/history";
import ProductPageContainer from "../container/ProductPageContainer";
import NavigationBarContainer from "../container/NavigationBarContainer";
import CartContainer from "../container/CartContainer";
import FilterContainer from "../container/FilterContainer";
import SearchContainer from "../container/SearchContainer";
import SearchPageContainer from "../container/SearchPageContainer";
import ProductListPageContainer from "../container/ProductListPageContainer";
import {setTextColor} from "../helper/setTextColor";

type AppProps = {
	getProducts: () => void,
	isIdle: boolean,
	theme: string,
	manufacturerTitle: string,
}

const App = (props: AppProps) => {
	const {getProducts, isIdle} = props;

	const colourRef = useRef(null);
	const [backgroundColor, setBackgroundColor] = useState("");
	useEffect(() => {
		getProducts();
	}, [getProducts]);

	useEffect(() => {
		if (colourRef) {
			// get primary color as rgb
			setBackgroundColor(window.getComputedStyle(colourRef.current).backgroundColor);
			setTextColor(backgroundColor);
		}
	}, [colourRef, backgroundColor]);

	const renderApp = () => {
		return <div className="sl-app" id={"sl-content-root"}>
			<div id="colorPicker" ref={colourRef}></div>
			<Router history={customHistory}>
				{isIdle
					? <Switch>
						<Route exact path="/" component={StartPageContainer}/>
						<Route>
							<Router history={customHistory}>
								<NavigationBarContainer/>
								<div className="content">
									<FilterContainer/>
									<Route path="/product/:productId" component={ProductPageContainer}/>
									<Route path="/category/:categoryKey" component={ProductListPageContainer}/>
									<Route path={"/search/:fuzzy"} component={SearchPageContainer}/>
									<CartContainer/>
									<SearchContainer/>
								</div>
							</Router>
						</Route>
					</Switch>
					: <Loading/>
				}
			</Router>
		</div>;
	};

	return renderApp();
};

export default App;
