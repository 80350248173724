
export const getMinProductCountPerPage = props => {
	const { currentPageIndex, itemsPerPage, totalItems } = props;
	if (currentPageIndex > 0) {
		return currentPageIndex * itemsPerPage + 1;
	}

	return totalItems > 0 ? 1 : 0;
};

export const getMaxProductCountPerPage = props => {
	const { currentPageIndex, totalItems, itemsPerPage, totalPages } = props;

	if (currentPageIndex > 0) {
		if ((currentPageIndex + 1) < totalPages) {
			return currentPageIndex * itemsPerPage + itemsPerPage;
		}

		return totalItems - (itemsPerPage * (totalPages - currentPageIndex - 1));
	};

	return itemsPerPage < totalItems ? itemsPerPage : totalItems;
};
