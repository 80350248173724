export const getTrafficOrigin = (): string => {
	const params = new URLSearchParams(window.location.search);
	const utm_medium = params.get("utm_medium");
	if (utm_medium) {
		const mediums = Array.isArray(utm_medium) ? utm_medium : [utm_medium];
		return mediums[0];
	}
	return null;
};

export const shouldTrackOrigin = (): boolean => {
	if (window.slDataLayer && window.slDataLayer.loaded) {
		return false;
	}
	window.slDataLayer = {loaded: true};
	return true;
};
