import type {ApplicationState} from "../reducers";
import NavigationBar from "../components/NavigationBar";
import {connect} from "react-redux";

type NavigationBarContainerProps = {};

function mapStateToProps(state: ApplicationState, props: NavigationBarContainerProps) {
	return {
		cartItemCount: state.cart.items ? state.cart.items.length : 0,
		cartDisabled: state.cart.cartDisabled,
	};
}

// function mapDispatchToProps(dispatch: Dispatch, ownProps: NavigationBarContainerProps) {
//
// }

export default connect(mapStateToProps, null)(NavigationBar);
