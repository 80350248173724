/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import type Product from "../models/Product";
import {Swiper} from "swiper/react";
import "./ProductListPage.scss";
import {Autoplay, Navigation} from "swiper";
import type {translate} from "../i18n/i18n";
import {withTranslation} from "react-i18next";
import type Category from "../models/Category";
import ProductDoubleSwiperSlider from "./ProductDoubleSwiperSlider";
import {useParams} from "react-router";
import Pagination from "./Pagination";
import {getBtnTextColor} from "../helper/setTextColor";
import {SPECIAL_CATEGORY} from "../actions/filter";
import detachMouseEvents from "../helper/SwiperUtil.cs";

export type ProductListPageProps = {
	products: Product[],
	goHome: () => void,
	t: translate,
	category: Category,
	resetCategory: () => void,
	setCategoryByKey: string => void,
	setPage: number => void,
	currentPageIndex: number,
	totalItems: number,
	totalPages: number,
	itemsPerPage: number,
	loadingNextPage: boolean,
};

const ProductListPage = (props: ProductListPageProps) => {
	const {products, category, setCategoryByKey} = props;
	const {categoryKey} = useParams();

	useEffect(() => {
		if (category.key !== categoryKey) {
			setCategoryByKey(categoryKey);
		}
	}, [categoryKey, category, setCategoryByKey]);

	const handleHistoryBack = e => {
		e.preventDefault();
		props.goHome();
		props.resetCategory();
	};

	return	<div className="main">
		<div className="control">
			<span><a className="toggle-detail" data-testid={"backButton"}
							 style={{color: getBtnTextColor()}} onClick={e => handleHistoryBack(e)}>
				{props.t("navigation.back")}
			</a></span>
			<span><a style={{display: "none"}}>{props.t("navigation.share")}</a></span>
		</div>
		<div className="head" data-testid={"categoryTitle"}>
			<span>
				{ (category.title === SPECIAL_CATEGORY.TOP)
					? props.t("main.topOffers")
					: (category.title === SPECIAL_CATEGORY.ALL ? props.t("main.topOffers") : category.title)
				}
			</span>
			<Pagination
				loadingNextPage={props.loadingNextPage}
				currentPageIndex={props.currentPageIndex}
				totalPages={props.totalPages}
				totalItems={props.totalItems}
				itemsPerPage={props.itemsPerPage}
				setPage={props.setPage}
				t={props.t}
			/>
		</div>
		<div id={"category"} className={"swiper"} data-testid="categoryPage">
			<Swiper
				onTouchMove={swiper => { detachMouseEvents(swiper); swiper.autoplay.stop(); }}
				modules={[Navigation, Autoplay]}
				className={"swiper-container swiper-list"}
				slidesPerView={1}
				spaceBetween={5}
				autoHeight={true}
				autoPlay={{delay: 9000}}
				loop={products.length > 8}
				loopFillGroupWithBlank={true}
				grabCursor={true}
				loopAdditionalSlides={7}
				navigation={{
					nextEl: ".slider-button-next-1",
					prevEl: ".slider-button-prev-1",
				}}
				autoplay={products.length > 8
					? { delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false }
					: false
				}
				onResize={swiper => {
					document.querySelectorAll(".swiper-list > .swiper-wrapper").forEach(it => {
						it.style.height = "auto";
					});
					swiper.updateAutoHeight();
				}}
			>
				<ProductDoubleSwiperSlider products={products}/>
			</Swiper>
			<div className="swiper-button-next swiper-button-next-tip slider-button-next-1" tabIndex={"0"} role={"button"}
				 aria-label={"Next slide"}/>
			<div className="swiper-button-prev swiper-button-prev-tip slider-button-prev-1" tabIndex={"0"} role={"button"}
				 aria-label={"Next slide"}/>
		</div>
	</div>;
};
export default withTranslation()(ProductListPage);
