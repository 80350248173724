// @flow
import type DetailedProduct from "../models/DetailedProduct";
import {SPECIAL_CATEGORY} from "../actions/filter";
import {customHistory} from "../helper/history";
import type Product from "../models/Product";
import {createSlice} from "@reduxjs/toolkit";

export type ContentReducerState = {
	productGroups: Product[],
	topProducts: Product[],
	activeProduct: ?DetailedProduct,
	meta: any,
	isIdle: boolean,
	disclaimer: string,
}

const initialState: ContentReducerState = {
	products: [],
	topProducts: [],
	activeProduct: null,
	disclaimer: "",
	meta: {
		categories: [],
		priceRange: null
	},
	isIdle: true
};

const contentSlice = createSlice({
		name: "content",
		initialState,
		reducers: {
			searchPhraseSubmitted(state) {
				state.activeProduct = null;
				state.isIdle = false;
			},
			categorySet(state, action) {
				const target = `/category/${action.payload ? action.payload.key : SPECIAL_CATEGORY.ALL}`;
				if (customHistory.location.pathname !== target &&
					action.payload.count !== 1) {
					customHistory.push(target);
				}
				state.activeProduct = null;
				state.isIdle = false;

			},
			productSet(state, action) {
				const product = action.payload;
				customHistory.push(`/product/${product ? product.id : "null"}`);
				state.isIdle = true;
			},
			configSuccessGotten(state, action) {
				state.disclaimer = action.payload.texts.disclaimer;
			},
			productsRequestFetched() {
			},
			topProductsRequestFetched(state) {
				state.isIdle = false;
			},
			productSuccessFetched(state, action) {
				state.isIdle = true;
				state.products = action.payload.products;
			},
			topProductSuccessFetched(state, action) {
				state.isIdle = true;
				state.products = action.payload.products;
				state.topProducts = action.payload.products;
				state.meta = action.payload.filterStats;
			},
			productsFailureFetched() {
			},
			topProductsFailureFetched(state) {
				state.isIdle = true;
			},
			productDetailRequestFetched(state) {
				state.isIdle = false;
			},
			productDetailSuccessFetched(state, action) {
				state.isIdle = true;
				state.activeProduct = action.payload;
			},
			productDetailFailureFetched(state, action) {
				state.isIdle = false;
				state.activeProduct = null;
			},
			productScopeReset(state) {
				state.activeProduct = null;
			}
		}
	}
);

export const {
	productSet,
	categorySet,
	productDetailFailureFetched,
	productDetailRequestFetched,
	productDetailSuccessFetched,
	productScopeReset,
	topProductsFailureFetched,
	configSuccessGotten,
	productsFailureFetched,
	productsRequestFetched,
	topProductsRequestFetched,
	topProductSuccessFetched,
	searchPhraseSubmitted,
	productSuccessFetched
} = contentSlice.actions;

export default contentSlice.reducer;
