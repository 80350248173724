// @flow

import type {ApplicationState} from "../reducers";
import type {Dispatch} from "redux";
import {getAllProducts, getProducts, getTopProducts} from "./products";
import type Category from "../models/Category";
import {categoryReset, menuSet, pageIndexReset, pageIndexSet, searchPhraseSet} from "../reducers/filterReducer";
import {categorySet, searchPhraseSubmitted} from "../reducers/contentReducer";

export const SPECIAL_CATEGORY = {
	TOP: "top",
	ALL: "all",
	listAll: () => [SPECIAL_CATEGORY.TOP, SPECIAL_CATEGORY.ALL],
};

export const setMenu = (menuOption: ?string) => {
	return (dispatch: Dispatch) => {
		dispatch(menuSet(menuOption));
	};
};

export const setSearchPhrase = (searchPhrase: string) => {
	return (dispatch: Dispatch) => {
		dispatch(searchPhraseSet(searchPhrase));
	};
};

export const submitSearchPhrase = (searchPhrase: string) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch(searchPhraseSubmitted(getState().filter.fuzzy));
		dispatch(getProducts());
	};
};

export const setCategory = (categoryKey: string = SPECIAL_CATEGORY.ALL) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const mainCategories: Category[] = getState().content.meta.categories;
		const subCategories: Category[] = [].concat.apply([], mainCategories.map(cat => cat.categories));
		const selectedCategories: Category[] = [...mainCategories, ...subCategories].filter(cat => categoryKey.includes(cat.key));
		let selectedCategory = selectedCategories.length > 0
			? selectedCategories[0]
			: {key: categoryKey, title: categoryKey};

		dispatch(categorySet(selectedCategory));

		if (categoryKey === SPECIAL_CATEGORY.ALL) {
			dispatch(getAllProducts());
		} else if (categoryKey === SPECIAL_CATEGORY.TOP) {
			dispatch(getTopProducts());
		} else {
			dispatch(getProducts());
		}
	};
};

export const resetCategory = () => {
	return (dispatch: Dispatch) => {
		dispatch(categoryReset());
	};
};

export const setPage = (page: number) => {
	return (dispatch: Dispatch) => {
		dispatch(pageIndexSet(page));
	};
};

export const resetPage = () => {
	return (dispatch: Dispatch) => {
		dispatch(pageIndexReset());
	};
};
