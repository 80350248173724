// @flow

import type {ApplicationState} from "../reducers";
import type {CartProps} from "../components/Cart";
import Cart from "../components/Cart";
import type {Dispatch} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {setMenu} from "../actions/filter";
import {removeFromCart} from "../actions/cart";
import {setProductScope} from "../actions/products";
import {customHistory} from "../helper/history";

function mapStateToProps(state: ApplicationState, ownProps: CartProps) {
	const sum = {
		value: state.cart.items.reduce((current, acc) => (acc.price ? acc.price.value : 0) + current, state.config.offerPrice.value),
		currency: state.config.offerPrice.currency
	};

	return {
		isSelected: state.filter.showMenu === "CALCULATOR",
		cartItems: state.cart.items,
		offerPrice: state.config.offerPrice,
		hidePrices: state.cart.hidePrices,
		priceSum: sum,
		defaultProductPrice: state.content.products.length >= 1 ? state.content.products[0].price : {}
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: CartProps) {
	return {
		closeCalculator: () => dispatch(setMenu()),
		removeItemFromCart: item => dispatch(removeFromCart(item)),
		setProductScope: product => {
			dispatch(setProductScope(product));
			customHistory.push(`/product/${product.id}`);
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cart));
