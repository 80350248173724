// @flow

import RootNodeProvider from "../helper/RootNodeProvider";
import {createSlice} from "@reduxjs/toolkit";

export type ConsentReducerState = {
	googleAnalytics: boolean,
};

let useConsentManager;
if (RootNodeProvider.getUclRootNode() && RootNodeProvider.getUclRootNode().dataset) {
	useConsentManager = RootNodeProvider.getUclRootNode().dataset.useConsentManager !== undefined;
}

const getInitialState = () => {
	if (useConsentManager) {
		return {
			googleAnalytics: false,
		};
	}

	const isTrackingAllowed = () => {
		if (!document.cookie) return false;
		const consentCookie = document.cookie.split("; ").find(row => row.startsWith("sl-app-accept-tracking"));
		const allowedByConsent = consentCookie ? (consentCookie.split("=")[1] !== "false") : false;
		const gaCookie = document.cookie.split("; ").find(row => row.startsWith("_ga"));
		const piwikCookie = document.cookie.split("; ").find(row => row.startsWith("_pk_id"));
		const cookieAlreadyPresent = !!gaCookie || !!piwikCookie;
		return (allowedByConsent || cookieAlreadyPresent);
	};

	return {
		googleAnalytics: isTrackingAllowed(),
	};
};

const initialState = getInitialState();

const consentSlice = createSlice({
	name: "consent",
	initialState,
	reducers: {
		googleAnalyticsSet(state, action) {
			state.googleAnalytics = action.payload;
		},
	}
});

export const {googleAnalyticsSet} = consentSlice.actions;
export default consentSlice.reducer;
