import type Product from "../models/Product";
import React from "react";
import {SwiperSlide} from "swiper/react";
import ProductSlide from "./ProductSlide";
import type {ApplicationState} from "../reducers";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {translate} from "../i18n/i18n";
import {chunkArrayInGroups} from "../helper/arrayUtil";
import "./ProductDoubleSwiperSlider.scss";

export type ProductDoubleSwiperSliderProps = {
	products: Product[],
	manufacturerTitle: string,
	t: translate,
}

const ProductDoubleSwiperSlider = (props: ProductDoubleSwiperSliderProps) => {
	const productList = chunkArrayInGroups(props.products, 4);

	const renderOemHint = () => {
		return <div className="list-claim" key={"oem_hint_div"} data-testid={"oemHint"}>
			<span className={"pad pro pro-00"}>
				<span>{props.t("productList.oemHint", {brand: props.manufacturerTitle})}</span>
			</span>
		</div>;
	};

	return <React.Fragment key={"product_List_" + productList.length}>
		{productList.map((products, index) => {
			return <SwiperSlide className={"swiper-slide grid"} key={"product_List_" + index}>
				<div key={products[0].id}>
					<ProductSlide product={products[0]} key={"product_List_1_" + index}/>
				</div>
				{products[1]
					? <div key={products[1].id}>
						<ProductSlide product={products[1]} key={"product_List_2_" + index}/>
					</div>
					: <div/>
				}
				{products[2]
					? <div key={products[2].id}>
						<ProductSlide product={products[2]} key={"product_List_3_" + index}/>
					</div>
					: renderOemHint()
				}
				{products[3]
					? <div key={products[3].id}>
						<ProductSlide product={products[3]} key={"product_List_4_" + index}/>
					</div>
					: products[2]
						? renderOemHint()
						: <div/>
				}
			</SwiperSlide>;
		})
		}
	</React.Fragment>;
};

function mapStateToProps(state: ApplicationState, ownProps: ProductSwiperSlideProps) {
	return {
		manufacturerTitle: state.config.manufacturerTitle
	};
}

function mapDispatchToProps(dispatch, ownProps: ProductSwiperSlideProps) {
	return {};
}

ProductDoubleSwiperSlider.displayName = "SwiperSlide";
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductDoubleSwiperSlider));
