// @flow

import type Vehicle from "../models/Vehicle";
import type Price from "../models/Price";
import RootNodeProvider from "../helper/RootNodeProvider";
import type {TrackingProperties} from "../api/ProductApi";
import {createSlice} from "@reduxjs/toolkit";

export type ConfigReducerState = {
	vehicle: Vehicle,
	dealerId: string,
	offerPrice: Price,
	storageId: ?string,
	language: ?string,
	intent: ?string,
	fractionDigits: ?string,
	trackingProperties: TrackingProperties,
	useConsentManager: boolean,
	manufacturerTitle: string,
	trackerLoaded: boolean,
}

export const getConfig: () => ConfigReducerState = () => {
	let config = {
		vehicle: null,
		dealerId: null,
		intent: null,
		fractionDigits: null,
		trackingProperties: {},
		useConsentManager: false,
		trackerLoaded: false,
	};
	if (RootNodeProvider.getUclRootNode()) {
		const {dealerid, brand = null, language = navigator.language, engine, hsn, tsn, model, series,
			currencycode = "EUR", price = "0", fin, storageid, intent, fractiondigits = "false",
			useConsentManager = false} = RootNodeProvider.getUclRootNode().dataset;
		config = {
			vehicle: {
				brand,
				engine,
				mkn: hsn,
				tkn: tsn,
				model,
				series,
				vin: fin
			},
			intent,
			fractionDigits: fractiondigits,
			language,
			storageId: storageid,
			dealerId: dealerid,
			offerPrice: {
				currency: currencycode,
				value: parseInt(price)
			},
			trackingProperties: {},
			useConsentManager: !!useConsentManager,
			trackerLoaded: false,
		};
	}
	return config;
};

const initialState: ConfigReducerState = getConfig();

const configSlice = createSlice({
	name: "config",
	initialState,
	reducers: {
		configSuccessGet(state,action) {
			state.trackingProperties = action.payload.configurationDetails.trackingProperties;
			state.manufacturerTitle = action.payload.dealerDetails.dealership.manufacturerTitle;
		},
		trackerLoaded(state,action) {
			state.trackerLoaded = action.payload;
		}
	}
});

export const {configSuccessGet, trackerLoaded} = configSlice.actions;
export default configSlice.reducer;
