// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import PriceBox from "../components/PriceBox";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {addToCart, removeFromCart} from "../actions/cart";
import type {Dispatch} from "redux";
import {CART_MAX_ITEMS} from "../reducers/cartReducer";
import {hasNoPrice} from "../helper/priceUtil";
import {toast} from "react-toastify";
import type {translate} from "../i18n/i18n";

type PriceBoxContainerProps = {}

function mapStateToProps(state: ApplicationState, ownProps: PriceBoxContainerProps) {
	return {
		detailedProduct: state.content.activeProduct,
		isInCart: state.cart.items.some(item => item.id === state.content.activeProduct.product.id),
		cartDisabled: state.cart.cartDisabled,
		hidePrices: state.cart.hidePrices || hasNoPrice(state.content.activeProduct.product),
		disclaimer: state.content.disclaimer,
		isCartLimitReached: state.cart.items.length >= CART_MAX_ITEMS,
	};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: PriceBoxContainerProps) {
	return {
		addToCart: (t: translate) => {
			dispatch(addToCart(ownProps.product));
			toast(t("cart.add"));
		},
		removeFromCart: () => {
			dispatch(removeFromCart(ownProps.product));
		},
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PriceBox)));
