/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React from "react";
import "./Cart.scss";
import type Product from "../models/Product";
import type {translate} from "../i18n/i18n";
import type Price from "../models/Price";
import currencyConverter from "../helper/currency";
import {getBtnTextColor} from "../helper/setTextColor";

export type CartProps = {
	isSelected: boolean,
	cartItems: Product[],
	offerPrice: Price,
	priceSum: Price,
	t: translate,
	removeItemFromCart: Product => void,
	hidePrices: boolean,
	defaultProductPrice: Price,
	setProductScope: Product => void
};

const Cart = (props: CartProps) => {
	const toggleCard = () => {
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("card-on");
		}
	};

	return <React.Fragment>
		<div className="transparentModal-card"
			 onClick={() => toggleCard()}
		/>
		<div className="card">
			<div className="control">
				<span><button className="toggle-card" style={{color: getBtnTextColor()}} onClick={() => toggleCard()}>{props.t("navigation.close")}</button></span>
				<span><button className="toggle-card" style={{visibility: "hidden"}}>Liste senden</button></span>
			</div>
			<div className="scroll">
				{!!(!props.hidePrices && props.offerPrice && props.offerPrice.value) &&
					<div className="cartItem">
						<span id="item-00" className="item">
							<span>{props.t("navigation.vehicle")}</span>
							<span><small>{currencyConverter(props.offerPrice, props.defaultProductPrice)}</small></span>
						</span>
						<a className="delete-dummy">
						</a>
					</div>
				}
				{props.cartItems.map(cartItem =>
					<div className="cartItem" key={cartItem.id} data-testid={"cartItem"}>
						<a id="item-01" className="item" onClick={e => {
							e.preventDefault();
							props.setProductScope(cartItem);
						}}
						   key={cartItem.articleNumber}>
							<span>{cartItem.name}</span>
							{(!props.hidePrices && cartItem.price && cartItem.price.value) && (
								<span>
									<small>{currencyConverter(cartItem.price)}</small>
									{cartItem.unitPrice && (
										<span className="unit-price-cart">
											<small> ({currencyConverter(cartItem.unitPrice.pricePerUnit)} / {cartItem.unitPrice.measuringUnit === "sqm" ? "m²"
												: cartItem.unitPrice.measuringUnit === "cbm" ? "m³" : cartItem.unitPrice.measuringUnit})
											</small>
										</span>
									)}
								</span>
							)}
						</a>
						<a className="delete" data-testid="cartDelete" onClick={e => {
							e.preventDefault();
							props.removeItemFromCart(cartItem);
						}}>
						</a>
					</div>
				)}
			</div>
			<div className="action">
				<span><small>{(props.offerPrice && props.offerPrice.value !== 0) ? props.t("cart.fullPrice") : props.t("cart.priceWithoutCar")}</small>
					<span className="price">{currencyConverter(props.priceSum)}</span>
				</span>
				<a href="/#" className="btn toggle-card" style={{visibility: "hidden"}}>Anfrage senden</a>
			</div>
		</div>
	</React.Fragment>;
};

export default Cart;
