// @flow
import type {StorageType} from "../../models/Memorizing";

export default class BaseStorage implements Memorizing<any, any> {
	key: string;
	storage: StorageType;

	constructor(dealerId: string, keySuffix: string, storage: StorageType = window.localStorage) {
		this.key = dealerId + keySuffix;
		this.storage = storage;
	}

	get(): any {
		return this.storage.getItem(this.key);
	}

	set(value: string): void {
		return this.storage.setItem(this.key, value);
	}

	clear(): void {
		return this.storage.removeItem(this.key);
	}
};
