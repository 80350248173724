// @flow
import {ToastContainer, Zoom} from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./ToastifyContainer.scss";

export type ToastProviderProps = {}

const ToastifyContainer = (props: ToastProviderProps) => {
	return (
		<ToastContainer
			id={"selekt-toastContainer"}
			className={"selekt-noPrint overlay"}
			autoClose={2700}
			closeButton={false}
			closeOnClick
			toastClassName="toastBody"
			bodyClassName="toastText"
			hideProgressBar
			transition={Zoom}
		/>);
};

export default ToastifyContainer;
