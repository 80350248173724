import React, {useState} from "react";
import type Category from "../models/Category";
import type {translate} from "../i18n/i18n";
import {withTranslation} from "react-i18next";
import "./Filter.scss";
import {getTextColor, getBtnTextColor} from "../helper/setTextColor";
import {SPECIAL_CATEGORY} from "../actions/filter";

export type FilterProps = {
	isSelected: boolean,
	selectedCategory: Category,
	availableCategories: Category[];
	setCategory: string => void,
	t: translate,
};

const Filter = (props: FilterProps) => {
	const [selectedCategory, setSelectedCategory] = useState(props.selectedCategory.key);

	const toggleFilter = () => {
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("menu-on");
		}
	};

	const setCategoryFilter = () => {
		props.setCategory(selectedCategory);
		toggleFilter();
	};

	const isCategorySelected = key => {
		return key === selectedCategory;
	};

	const resetFilter = () => {
		props.setCategory(SPECIAL_CATEGORY.TOP);
		toggleFilter();
	};

	return <React.Fragment>
		<div className="transparentModal-filter"
			 onClick={() => toggleFilter()}
		/>
		<div className="menu" data-testid={"filterModal"}>
			<div className="control">
				<span><button className="toggle-menu" onClick={() => resetFilter()}><small style={{color: getBtnTextColor()}}>{props.t("navigation.filter.reset")}</small></button></span>
				<span><button className="toggle-menu" onClick={() => setCategoryFilter()}><small style={{color: getBtnTextColor()}}>{props.t("navigation.filter.ready")}</small></button></span>
			</div>
			<div className="scroll">
				<input onClick={() => setSelectedCategory(SPECIAL_CATEGORY.TOP)} type="radio" name="cat-top" id="cat-top"
					   onChange={() => {}} data-testid={"filterOption"}
					   checked={isCategorySelected(SPECIAL_CATEGORY.TOP)}/>
				<label htmlFor="cat-top" data-testid={"topProductsFilter"}>
					<span>{props.t("navigation.filter.topProducts")}</span>
					<span><small></small></span>
				</label>
				{props.availableCategories.map((category, index) =>
					<React.Fragment key={category.key}>
						<input onClick={() => setSelectedCategory(category.key)} type="radio" name={"cat-" + index} id={"cat-" + (index)}
							   checked={isCategorySelected(category.key)} onChange={() => {}} data-testid={"filterOption"}/>
						<label htmlFor={"cat-" + (index)}>
							<span>{category.title}</span>
							<span><small>{category.count}</small></span>
						</label>
					</React.Fragment>
				)}
			</div>
			<div className={"action"}>
				<button type={"button"} style={{color: getTextColor()}} className={"btn toggle-menu"} onClick={() => setCategoryFilter()}>
					{props.t("navigation.filter.show")}
				</button>
			</div>
		</div>
	</React.Fragment>;
};

export default withTranslation()(Filter);
