// @flow
import ReactGA from "react-ga4";
import type {Tracking} from "../../models/Tracking";
import BaseTracker from "./BaseTracker";

export default class GoogleTracker extends BaseTracker implements Tracking {
	constructor(trackingIds: string[], withEcommerce: boolean = false, dealerId: string = "unknown", dealerName: string = "unknown", customerNo: string = "unknown") {
		super(trackingIds[0], withEcommerce);
		const gtagOptions = {
			"dimension1": dealerId,
			"dimension2": dealerName,
			"dimension3": customerNo,
		};

		ReactGA.initialize(trackingIds
				.map(id => ({
					trackingId: id,
					gaOptions: {},
					gtagOptions
				})),
			{
				testMode: false,
			}
		);
	}

	handleGenericEvent(cat: string, action: string, label: any) {
		ReactGA.event(action, {
			category: cat,
			label: label,
			event_label: label,
			event_source: "UCL"
		});
	}

	handleViewItemEvent(product: any) {
		ReactGA.gtag("event", "view_item", {
			items: [{
				item_id: product.id,
				item_name: product.name,
				price: product.price,
				event_source: "UCL"
			}]
		});
	}

	handleSearchEvent(searchTerm: string) {
		if (!searchTerm) return;

		ReactGA.gtag("event", "search", {
			search_term: searchTerm,
			event_source: "UCL"
		});
	}

	handleAddToCartEvent(product: any) {
		ReactGA.gtag("event", "add_to_cart", {
				items: [{
					item_id: product.id,
					item_name: product.name,
					price: product.price,
					event_source: "UCL"
				}]
			});
	}
	handleRemoveFromCartEvent(product: any) {
		ReactGA.gtag("event", "remove_from_cart", {
			items: [{
				item_id: product.id,
				item_name: product.name,
				price: product.price,
				event_source: "UCL"
			}]
		});
	}


}
