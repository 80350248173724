// @flow

class RootNodeProvider {
	getUclRootNode = () => {
		const root = document.getElementById("itt-sl-ucl")
			? document.getElementById("itt-sl-ucl")
			: document.getElementById("root");

		if (!root) {
			if (process.env.NODE_ENV === "test") {
				const testRoot = document.querySelector("body");
				testRoot.dataset["dealerId"] = "volvo_99999";
				testRoot.id = "itt-sl-ucl";
				return testRoot;
			}
			console.error("root node should look like : <div data-dealer-id=\"your_dealerNo\" id=\"itt-sl-ucl\">");
			return null;
		}

		return root;
	};
}

const RootNodeProviderInstance = new RootNodeProvider();

export default RootNodeProviderInstance;
