import React from "react";
import "./NavigationBar.scss";
import {withTranslation} from "react-i18next";
import type {translate} from "../i18n/i18n";
import SearchIcon from "./icons/SearchIcon";
import ShoppingBag from "./icons/ShoppingBag";

type NavigationBarProps = {
	cartItemCount: number,
	t: translate,
	cartDisabled: boolean,
};

const NavigationBar = (props: NavigationBarProps) => {
	const setFocus = selector => {
		const input = document.querySelector(selector);
		input.focus();
		input.select();
	};

	const toggleCard = () => {
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("card-on");
			node.classList.remove("menu-on");
		}
	};
	const toggleSearch = e => {
		e.preventDefault();
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("search-on");

			const searchContainer = document.querySelector(".search-on");

			if (searchContainer) {
				setFocus(".searchTerm");
			}
		}
	};

	const toggleFilter = () => {
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("menu-on");
			node.classList.remove("card-on");
		}
	};

	return <React.Fragment>
		<nav>
			<span>
				<button className="filter toggle-menu" type={"button"} data-testid={"filter"}
								onClick={() => toggleFilter()}>
					<span className={"filter-text"}>{props.t("navigation.filtern")}</span>
					<span className={"filter-close-text"} data-testid={"closeFilter"}>{props.t("navigation.closeFilter")}</span>
				</button>
			</span>
			<span>
				{
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					<a className="glass toggle-search" data-testid={"openSearch"}
						 onClick={e => toggleSearch(e)}>
						<SearchIcon fill={"black"}/>
					</a>
				}
				{ !props.cartDisabled &&
					<button className="cart toggle-card" data-testid={"cartIcon"}
									type={"button"} onClick={() => toggleCard()}>
						<ShoppingBag fill={"#000000"} className={"cart"}/>
						{!!props.cartItemCount && <span className="bubble" data-testid={"cartCount"}>{props.cartItemCount}</span>}
					</button>
				}
			</span>
		</nav>
	</React.Fragment>;
};

export default withTranslation()(NavigationBar);
