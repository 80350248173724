// @flow

import type {ApplicationState} from "../reducers";
import type Product from "../models/Product";
import type {Dispatch} from "redux";
import {toast} from "react-toastify";
import {CART_MAX_ITEMS, cartCleared, fromCartRemoved, toCartAdded} from "../reducers/cartReducer";

export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_MAX_ITEMS = "ADD_TO_CART_MAX_ITEMS";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const addToCart = (product: ?Product, errorsMsg) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		if (getState().cart.items.length >= CART_MAX_ITEMS) {
			toast(errorsMsg);
			return dispatch({
				type: ADD_TO_CART_MAX_ITEMS,
			});
		}

		dispatch(toCartAdded(product || getState().content.activeProduct.product));
	};
};

export const removeFromCart = (product: ?Product) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch(fromCartRemoved(product || getState().content.activeProduct.product));
	};
};

export const clearCart = () => {
	return (dispatch: Dispatch) => {
		dispatch(cartCleared());
	};
};
