import React from "react";
import currencyConverter from "../helper/currency";
import {addToCart, removeFromCart} from "../actions/cart";
import {setProductScope} from "../actions/products";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../reducers";
import {hasNoPrice} from "../helper/priceUtil";
import {SwiperSlide} from "swiper/react";
import type {translate} from "../i18n/i18n";
import "./ProductSwiperSlider.scss";
import type Product from "../models/Product";
import {getTextColor} from "../helper/setTextColor";
import ShoppingBag from "./icons/ShoppingBag";

export type ProductSwiperSlideProps = {
	product: Product,
	cartDisabled: boolean,
	key: string,
	addToCart: string => void,
	removeFromCart: () => void,
	setProductScope: Product => void,
	t: translate,
	className: ?string,
	isSelected: boolean,
}

const ProductSwiperSlide = (props: ProductSwiperSlideProps) => {
	const [overlay, setOverlay] = React.useState(false);
	const {product, setProductScope, addToCart, removeFromCart} = props;
	const unitPrice = product.unitPrice;
	const handleAddToCart = () => {
		addToCart(props.t("navigation.maxBookmarksReached"));
		setOverlay(true);
	};
	const handleRemoveFromCart = () => {
		removeFromCart();
		setOverlay(false);
	};

	return <SwiperSlide className={"swiper-slide " + props.className} key={"_" + props.key}>
		<span className="pad pro pro-01" data-testid={"product-slide"}>
			<span>
				<button onClick={() => setProductScope(product)} className={"img toggle-detail " + (overlay ? "plopOverlay" : "")}
								style={{backgroundImage: `url('${product.galleryImage.previewImage ? product.galleryImage.previewImage.attachmentUrl : product.galleryImage.attachmentUrl}')`}}
								data-testid={"openProduct"}
				>
					<span/>
					<span style={{color: getTextColor()}}>{props.t("cart.add")}</span>
				</button>
				<span className="title" data-testid={"productTitle"}>{product.name}</span>
			</span>
			<span>
				<span className="prices">
					{product.recommendedRetailPrice &&
						<span className="crossed_price" data-testid={"retailPrice"}>
							{currencyConverter(product.recommendedRetailPrice)}
						</span>}
					{product.price && (
						<div>
							<span className="price" data-testid={"price"}>
								{currencyConverter(product.price)}
							</span>
							{unitPrice && (
							<span className="unit-price">
								<small>
									({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
								</small>
							</span>
							)}
						</div>
					)}
				</span>
				<span className="links">
					<button className="toggle-shop toggle-detail" data-testid={"productDetails"}
							onClick={() => setProductScope(product)}>
						{props.t("navigation.details")}
					</button>
					{!props.cartDisabled && (props.isSelected
						? <button className={"add toggle-card-add-01 productIsSelected"}
								  onClick={() => handleRemoveFromCart()} data-testid={"removeProduct"}>
							{props.t("navigation.remove")}
							<ShoppingBag className={"shoppingBagafter"} fill={getTextColor()}/>
						</button>
						: <button className={"add toggle-card-add-01"} onClick={() => handleAddToCart()} data-testid={"addProduct"}>
							{props.t("navigation.add")}
							<ShoppingBag className={"shoppingBagbefore"}/>
						</button>)
					}
				</span>
			</span>
		</span>
	</SwiperSlide>;
};

function mapStateToProps(state: ApplicationState, ownProps: ProductSwiperSlideProps) {
	return {
		cartDisabled: state.cart.cartDisabled,
		hidePrices: state.cart.hidePrices || hasNoPrice(ownProps.product),
		isSelected: state.cart.items.some(item => item.id === ownProps.product.id)
	};
}

function mapDispatchToProps(dispatch, ownProps: ProductSwiperSlideProps) {
	return {
		addToCart: (errorMsg: string) => dispatch(addToCart(ownProps.product, errorMsg)),
		removeFromCart: () => dispatch(removeFromCart(ownProps.product)),
		setProductScope: product => {
			dispatch(setProductScope(product));
			ownProps.history.push(`/product/${product.id}`);
		}
	};
}

ProductSwiperSlide.displayName = "SwiperSlide";
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductSwiperSlide)));
