// @flow
import ReactPiwik from "react-piwik";
import BaseTracker from "./BaseTracker";

export default class PiwikTracker extends BaseTracker {
	constructor(trackingId: string, trackingHostUrl: string, withEcommerce: boolean = false) {
		super(trackingId, withEcommerce);
		this.tracker = new ReactPiwik({
			siteId: this.trackingId,
			jsFilename: this.trackingId + ".js",
			url: trackingHostUrl
		});
	}

	handleGenericEvent(cat: string, action: string, label: any) {
		ReactPiwik.push(["trackEvent", `${cat} ${action}`, label]);
	}

	handleOrderEvent(id: string, revenue: number) {
		ReactPiwik.push([`${this.eventPrefix}SL_purchased`, id, revenue]);
	}

	handleItemEvent(id: string, name: string, articleNumber: string, price: number) {
		ReactPiwik.push([`${this.eventPrefix}add to cart`, id, price]);
	}
}
