// @flow

import type {ApplicationState} from "../reducers";
import type {FilterProps} from "../components/Filter";
import Filter from "../components/Filter";
import type {Dispatch} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {resetPage, setCategory, setMenu} from "../actions/filter";

function mapStateToProps(state: ApplicationState, ownProps: FilterProps) {
	return {
		isSelected: state.filter.showMenu === "FILTER",
		availableCategories: state.filter.filterStats.categories,
		selectedCategory: state.filter.filter.selectedCategory
	};
}

function mapDispatchToProps(dispatch: Dispatch, state: ApplicationState) {
	return {
		closeCalculator: () => dispatch(setMenu()),
		setCategory: (key: string = {}) => {
			dispatch(resetPage());
			dispatch(setCategory(key));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Filter));
