// @flow
import type Category from "../models/Category";
import {createSlice} from "@reduxjs/toolkit";
import {categorySet, productSuccessFetched, searchPhraseSubmitted, topProductSuccessFetched} from "./contentReducer";

type FilterStats = {
	categories: Category[]
}

type FilterOption = {
	selectedCategory: {},
	page: number,
	totalPages: number,
	totalItems: number,
	itemsPerPage: number
}

export type FilterReducerState = {
	searchPhrase: string,
	filter: FilterOption,
	filterStats: FilterStats,
	isLoading: boolean,
	showMenu: "FILTER" | "CALCULATION" | null,
	fuzzy: string,
	loadingNextPage: boolean,
};

const initialState = {
	searchPhrase: "",
	filter: {
		selectedCategory: {},
		page: 0,
		totalPages: 0,
		totalItems: 0,
		itemsPerPage: 100
	},
	filterStats: {
		categories: []
	},
	isLoading: false,
	showMenu: null,
	fuzzy: "",
	loadingNextPage: false
};

const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		menuSet(state, action) {
			state.showMenu = state.showMenu !== action.payload ? action.payload : null;
		},
		searchPhraseSet(state, action) {
			state.fuzzy = action.payload;
			state.category = {};
		},
		categoryReset(state) {
			state.filter.selectedCategory = {};
		},
		filterApplied(state, action) {
			state.filter[action.payload.key] = action.payload.value;

		},
		pageIndexSet(state, action) {
			state.filter.page = action.payload >= 0 ? action.payload : 0;
			state.loadingNextPage = true;
		},
		pageIndexReset(state) {
			state.filter.page = 0;
		},
	},
	extraReducers:
		builder => {
			builder
				.addCase(productSuccessFetched, (state, action) => {
					state.filter.totalItems = action.payload.productCount;
					state.filter.totalPages = action.payload.totalPages;
					state.loadingNextPage = false;
				})
				.addCase(topProductSuccessFetched, (state, action) => {
					state.filter.totalItems = action.payload.productCount;
					state.filter.totalPages = action.payload.totalPages;
					state.filterStats.categories = action.payload.filterStats.categories;
				})
				.addCase(categorySet, (state, action) => {
					state.filter.selectedCategory = action.payload || {};
					state.showMenu = null;
					state.fuzzy = "";
				})
				.addCase(searchPhraseSubmitted, state => {
					state.category = {};
				});
		}

});

export const {
	menuSet,
	searchPhraseSet,
	categoryReset,
	pageIndexSet,
	pageIndexReset
} = filterSlice.actions;

export default filterSlice.reducer;