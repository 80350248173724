/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React from "react";
import {Trans} from "react-i18next";
import "./Pagination.scss";
import {getMaxProductCountPerPage, getMinProductCountPerPage} from "../helper/paginationUtil";

export type PaginationProps = {
	currentPageIndex: number,
	totalItems: number,
	totalPages: number,
	itemsPerPage: number,
	setPage: () => void,
	t: () => void,
	loadingNextPage: boolean,
}

const Pagination = (props: PaginationProps) => {
	return props.totalItems === 0
		? <span className="pagina">
			<div>
				{ props.t("main.noArticleFound") }
			</div>
		</span>
		: <span className="pagina" data-testid={"pagniation"}>
			{ props.currentPageIndex > 0
			  ? <a onClick={() => props.setPage(props.currentPageIndex - 1)}></a>
			  : <a className="disable"></a>
		  }
			{ props.loadingNextPage
				? (<div className="dot-elastic"></div>)
				: <Trans
					i18nKey="main.article"
					values={{
						min: getMinProductCountPerPage(props),
						max: getMaxProductCountPerPage(props),
						productCount: props.totalItems
					}}
					/* eslint-disable-next-line react/jsx-key */
					components={[<span></span>]}
				/>
			}
			{ props.currentPageIndex < props.totalPages - 1
				? <a onClick={() => props.setPage(props.currentPageIndex + 1)}></a>
				: <a className="disable"></a>
			}
		</span>;
};

export default Pagination;
