let TEXT_COLOR = "#ffffff";
let BTN_TEXT_COLOR = "var(--sofa)";

export const getTextColor = () => {
	return TEXT_COLOR;
};

export const getBtnTextColor = () => {
	return BTN_TEXT_COLOR;
};
export const setTextColor = primaryColor => {
	const getBrightness = rgb => {
		rgb = rgb.replace(/[^\d,.]/g, "").split(",");

		let r = rgb[0];
		let g = rgb[1];
		let b = rgb[2];

		return ((r * 0.299 + g * 0.587 + b * 0.114) / 255.0);
	};
	const isBright = getBrightness(primaryColor) > 0.6;
	TEXT_COLOR = isBright ? "#000000" : "#ffffff";
	BTN_TEXT_COLOR = isBright ? "#000000" : "var(--sofa)";
};
