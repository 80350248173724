import type {ApplicationState} from "../reducers";
import {connect} from "react-redux";
import type ProductModel from "../models/Product";
import type {Dispatch} from "redux";
import type Category from "../models/Category";
import {customHistory} from "../helper/history";
import {resetCategory, resetPage, setCategory, setPage} from "../actions/filter";
import ProductListPage from "../components/ProductListPage";
import {getProducts} from "../actions/products";

type CategoryPageContainerProps = {
	product: ProductModel,
	category: Category
};

function mapStateToProps(state: ApplicationState, props: CategoryPageContainerProps) {
	return {
		products: state.content.products,
		category: state.filter.filter.selectedCategory,
		currentPageIndex: state.filter.filter.page,
		totalItems: state.filter.filter.totalItems,
		totalPages: state.filter.filter.totalPages,
		itemsPerPage: state.filter.filter.itemsPerPage,
		loadingNextPage: state.filter.loadingNextPage,
	};
}

function mapDispatchToProps(dispatch: Dispatch, props: CategoryPageContainerProps) {
	return {
		goHome: () => customHistory.push("/"),
		setPage: (page: number) => {
			dispatch(setPage(page));
			dispatch(getProducts());
		},
		setCategoryByKey: (key: string) => {
			dispatch(resetPage());
			dispatch(setCategory(key));
		},
		resetCategory: () => dispatch(resetCategory())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);
