// @flow

import RootNodeProvider from "./RootNodeProvider";
import {trackers} from "./tracker";
import {fetchConfig} from "../actions/products";
import {googleAnalyticsSet} from "../reducers/consentReducer";

export const CONSENT = {
	GOOGLE_ANALYTICS: {
		SET: "CONSENT_GOOGLE_ANALYTICS_SET",
		REVOKE: "CONSENT_GOOGLE_ANALYTICS_REVOKE",
		REQUEST: "CONSENT_GOOGLE_ANALYTICS_REQUEST",
	},
};

class EventHandler {
	store;
	targetNode;

	constructor(store) {
		this.store = store;
		this.targetNode = RootNodeProvider.getUclRootNode();
		if (!this.targetNode) return;
		this.targetNode.addEventListener(CONSENT.GOOGLE_ANALYTICS.SET, () => {
			if (this.store.getState().consent.googleAnalytics === false) {
				this.store.dispatch(googleAnalyticsSet(true));
				trackers.length = 0;
				this.store.dispatch(fetchConfig());
			}
		});
		this.targetNode.addEventListener(CONSENT.GOOGLE_ANALYTICS.REVOKE, () => {
			if (this.store.getState().consent.googleAnalytics === true) {
				this.store.dispatch(googleAnalyticsSet(false));
				trackers.length = 0;
				this.store.dispatch(fetchConfig());
			}
		});
	}

	requestGoogleAnalyticsConsent = () => {
		const event = new CustomEvent(CONSENT.GOOGLE_ANALYTICS.REQUEST);
		const rootNode = RootNodeProvider.getUclRootNode();
		if (rootNode) rootNode.dispatchEvent(event);
	};
}

export default EventHandler;
