// @flow
import React from "react";
import {createRoot} from "react-dom/client";
import Root from "./Root";
import AppContainer from "./container/AppContainer";
import "./i18n/i18n.js";
import BrowserNotSupported from "./components/BrowserNotSupported";
import RootNodeProvider from "./helper/RootNodeProvider";

const isInternetExplorer = !!document.documentMode;
const shouldRenderInstantly = RootNodeProvider.getUclRootNode() && !(RootNodeProvider.getUclRootNode().dataset.delayed !== undefined);

const render = () => {
	if (RootNodeProvider.getUclRootNode()) {
		const root = createRoot(RootNodeProvider.getUclRootNode());
		root.render(
			<Root>
				{isInternetExplorer
					? <BrowserNotSupported/>
					: <AppContainer/>
				}
			</Root>
		);
	}
};

if (shouldRenderInstantly) {
	render();
} else window["renderUcl"] = render;
