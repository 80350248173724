import React from "react";

type ShoppingBagProps = {
	className: string,
	fill: string
}

const ShoppingBag = (props: ShoppingBagProps) => {
	return (
		<svg className={`shoppingBag ${props.className}`} fill={props.fill || "#000000"}
		     xmlns="http://www.w3.org/2000/svg"
		     enableBackground="new 0 0 24 24"
		     height="25px" viewBox="0 0 24 24" width="25px">
			<path height="15px" width="15px"
			      d="M18,6h-2c0-2.21-1.79-4-4-4S8,3.79,8,6H6C4.9,6,4,6.9,4,8v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8C20,6.9,19.1,6,18,6z M12,4c1.1,0,2,0.9,2,2h-4C10,4.9,10.9,4,12,4z M18,20H6V8h2v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8h4v2c0,0.55,0.45,1,1,1s1-0.45,1-1V8 h2V20z"/>
		</svg>);
};

export default ShoppingBag;
