import React, {useState} from "react";
import "./Search.scss";
import type {translate} from "../i18n/i18n";
import {getBtnTextColor} from "../helper/setTextColor";
import SearchIcon from "./icons/SearchIcon";

export type SearchProps = {
	setSearch: string => void,
	searchPhrase: string,
	t: translate
};

const Search = (props: SearchProps) => {
	const [fuzzy, setFuzzy] = useState(props.searchPhrase || "");
	const toggleSearch = e => {
		e.preventDefault();
		const node = document.getElementById("sl-content-root");
		if (node) {
			node.classList.toggle("search-on");
		}
	};
	const handleSearch = e => {
		e.preventDefault();
		if (fuzzy !== null && fuzzy.length > 0) {
			props.setSearch(fuzzy);
		}
	};

	return <React.Fragment>
		<div className="search">
			<div className="control">
				<span>
					{
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a className="toggle-search" style={{color: getBtnTextColor()}} onClick={e => toggleSearch(e)}>
							{props.t("navigation.searching.close")}
						</a>
					}
				</span>
				<span>
					{
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a className="toggle-megasearch" style={{display: "none"}}>
							{props.t("navigation.searching.expand")}
						</a>
					}

				</span>
			</div>
			<div className="scroll">
				<form onSubmit={e => handleSearch(e)}>
					<div className="searchbox">
						<input type="search" className="searchTerm" onChange={e => setFuzzy(e.target.value)} value={fuzzy}
							   placeholder={props.t("navigation.searching.placeholder")}/>
						<button className={"submit-button"} type={"submit"}>
							<SearchIcon/>
						</button>
					</div>
				</form>

				<div className="megasearch" style={{display: "none"}}>
					<input type="checkbox" name="search-cat" id="search-cat-00"/>
					<label htmlFor="search-cat-00" checked="">
						<span>
							TOP Angebote
						</span>
						<span><small>0000</small></span>
					</label>
					<input type="checkbox" name="search-cat" id="search-cat-01"/>
					<label htmlFor="search-cat-01">
						<span>
							Packen und Laden
						</span>
						<span><small>0000</small></span>
					</label>
				</div>

			</div>
			<div className="action">
				{
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					<a className="btn toggle-search">
						000 {props.t("navigation.searching.show")}
					</a>
				}
			</div>
		</div>
	</React.Fragment>;
};

export default Search;
