// @flow
import React from "react";

type ZoomProps = {
	img?: string;
}

const Zoom = (props:ZoomProps) => {
	const {img} = props;

	return <img src={img} alt={""} style={{height: 100 + "%", width: 100 + "%", objectFit: "contain"}}/>;
}
;

export default Zoom;
