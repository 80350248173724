// @flow
import React from "react";
import type DetailedProduct from "../models/DetailedProduct";
import type {translate} from "../i18n/i18n";
import "./PriceBox.scss";
import currencyConverter from "../helper/currency";
import {getTextColor} from "../helper/setTextColor";

type PriceBoxProps = {
    t: translate,
    detailedProduct: DetailedProduct,
    isInCart: boolean,
    addToCart: () => void,
    removeFromCart: () => void,
    cartDisabled: boolean,
    hidePrices: boolean,
    disclaimer: string,
    isCartLimitReached: boolean
};

const PriceBox = (props: PriceBoxProps) => {
	const PURCHASE_TYPE = {
		MOUNTING: "MOUNTING",
		PICKUP: "PICKUP",
	};

	const removeFromCart = e => {
		e.preventDefault();
		props.removeFromCart();
	};

	const addToCart = e => {
		e.preventDefault();
		props.addToCart(props.t);
	};

	const getDefaultType = () => {
		const {mounting} = props.detailedProduct.product.distributionChannels;
		if (mounting) return PURCHASE_TYPE.MOUNTING;
		return PURCHASE_TYPE.PICKUP;
	};

	const getRecommendedRetailPrice = (purchaseType: string) => {
		if (props.hidePrices) return null;
		switch (purchaseType) {
			case PURCHASE_TYPE.MOUNTING:
				return props.detailedProduct.product.distributionChannels.mounting.recommendedRetailPrice;
			default:
				return props.detailedProduct.product.recommendedRetailPrice;
		}
	};

	const purchaseType = getDefaultType();

	const renderCartButton = () => {
		if (props.cartDisabled) return null;
		return props.isInCart
			? <button type={"button"} data-testid={"cartButtonRemove"}
								style={{color: getTextColor()}} className="btn toggle-card-add-00" onClick={e => removeFromCart(e)}>
				{props.t("productDetail.priceBox.removeItem")}
			</button>
			: props.isCartLimitReached
				? <button type={"button"} data-testid={"cartButtonLimited"}
									style={{color: getTextColor()}} className="btn toggle-card-add-00" onClick={e => e.preventDefault()}>
					{props.t("navigation.maxBookmarksReached")}
				</button>
				: <button type={"button"} data-testid={"cartButtonAdd"}
									style={{color: getTextColor()}} className="btn toggle-card-add-00" onClick={e => addToCart(e)}>
					{props.t("productDetail.priceBox.addItem")}
				</button>;
	};

	const renderPrice = (purchaseType: string) => {
		if (props.hidePrices) return null;
		switch (purchaseType) {
			case PURCHASE_TYPE.MOUNTING:
				return renderMountingPrice();
			default:
				return renderPickupPrice();
		}
	};

	const renderPickupPrice = () => {
		const { product } = props.detailedProduct;
		const price = product.price;
		const unitPrice = product.unitPrice;

		return (
			<div data-testid={"pricebox"}>
				{price && (
					<div className={"price"}>
						{currencyConverter(price)}
					</div>
				)}
				{price && unitPrice && (
					<span>
						<small>
							({currencyConverter(unitPrice.pricePerUnit)} / {unitPrice.measuringUnit === "sqm" ? "m²" : unitPrice.measuringUnit === "cbm" ? "m³" : unitPrice.measuringUnit})
						</small>
						<div>
							<small>{props.t("productDetail.priceBox.withVat")}</small>
						</div>
					</span>
				)}
				
			</div>
		);
	};

	const renderMountingPrice = () => {
		return <div>
			<span
				className={"price"}>{currencyConverter(props.detailedProduct.product.distributionChannels.mounting.price)}</span>
			<span><small>{props.t("productDetail.priceBox.withVatAndMounting")}</small></span>
			{props.detailedProduct.product.unitPrice && <span>
				<small>
					({currencyConverter(props.detailedProduct.product.unitPrice.pricePerUnit)} / {props.detailedProduct.product.unitPrice.measuringUnit})
				</small>
			</span>
			}
		</div>;
	};

	const recommendedRetailPrice = getRecommendedRetailPrice();

	return (!props.hidePrices || !props.cartDisabled)
		? <div>
			<span className="prices_detail">
				{recommendedRetailPrice &&
					<span>
						<span className="crossed_price_detail">{currencyConverter(recommendedRetailPrice)}</span>
					</span>
				}
				<span>
					{renderPrice(purchaseType)}
					{renderCartButton()}
				</span>
			</span>
		</div>
		: null;
};

export default PriceBox;
