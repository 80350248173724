// @flow

import type Product from "../models/Product";
import EventEmitter from "../helper/event";
import CookieStorage from "../helper/storage/CookieStorage";
import RootNodeProvider from "../helper/RootNodeProvider";
import {createSlice} from "@reduxjs/toolkit";

export const CART_MAX_ITEMS = 15;

export type CartReducerState = {
	cartDisabled: boolean,
	hidePrices: boolean,
	items: Product[];
};

const getCartConfig: () => boolean = () => {
	return !!RootNodeProvider.getUclRootNode() && (
		RootNodeProvider.getUclRootNode().dataset.cartDisabled !== undefined ||
		RootNodeProvider.getUclRootNode().dataset.cartdisabled !== undefined
	);
};

const getHidePrices: () => boolean = () => {
	return !!RootNodeProvider.getUclRootNode() && RootNodeProvider.getUclRootNode().dataset.hidePrices !== undefined;
};

const initialState = {
	cartDisabled: getCartConfig(),
	hidePrices: getHidePrices(),
	items: []
};

const eventEmitter = new EventEmitter();

const setCart = (items, state) => {
	const {
		dealerid,
		engine = "",
		hsn = "",
		tsn = "",
		model = "",
		series = "",
		fin = "",
		storageid
	} = RootNodeProvider.getUclRootNode().dataset;
	if (!state.cartDisabled) {
		const cartStorage = storageid ? new CookieStorage(storageid) : new CookieStorage(dealerid + hsn + tsn + model + series + engine + fin);
		cartStorage.set(JSON.stringify(items));
	}
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		cartSuccessRestored(state, action) {
			const restoredItems = [...state.items, ...action.payload];
			eventEmitter.emitEvent("CART_CHANGE", restoredItems);
			state.items = action.payload;
		},
		toCartAdded(state, action) {
			const items = [...state.items, action.payload];
			setCart({items: items.map(item => item.id)}, state);
			eventEmitter.emitEvent("CART_CHANGE", items);
			state.items = items;
		},
		fromCartRemoved(state, action) {
			const filteredItems = state.items.filter(product => action.payload.id !== product.id);
			setCart({items: filteredItems.map(item => item.id)}, state);
			eventEmitter.emitEvent("CART_CHANGE", filteredItems);
			state.items = filteredItems;
		},
		cartCleared(state) {
			setCart({items: []}, state);
			eventEmitter.emitEvent("CART_CHANGE", []);
			state.items = [];
		}
	}
});

export const {toCartAdded, fromCartRemoved, cartCleared, cartSuccessRestored} = cartSlice.actions;

export default cartSlice.reducer;
