/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import type Product from "../models/Product";
import {Swiper} from "swiper/react";
import "./ProductListPage.scss";
import {Autoplay, Navigation} from "swiper";
import type {translate} from "../i18n/i18n";
import {withTranslation} from "react-i18next";
import ProductDoubleSwiperSlider from "./ProductDoubleSwiperSlider";
import {useParams} from "react-router";
import Pagination from "./Pagination";
import detachMouseEvents from "../helper/SwiperUtil.cs";

export type SearchPageProps = {
	products: Product[],
	goHome: () => void,
	t: translate,
	setSearch: string => void,
	searchPhrase: string,
	selectedCategory: string,
	setPage: number => void,
	currentPageIndex: number,
	totalItems: number,
	totalPages: number,
	itemsPerPage: number
};

const SearchPage = (props: SearchPageProps) => {
	const {products, searchPhrase, setSearch} = props;
	const {fuzzy} = useParams();

	useEffect(() => {
		if (searchPhrase === null || fuzzy !== searchPhrase) {
			setSearch(fuzzy);
		}
	}, [searchPhrase, setSearch, fuzzy]);

	const handleHistoryBack = e => {
		e.preventDefault();
		props.goHome();
	};

	return	<div className="main" data-testid={"searchPage"}>
		<div className="control">
			<span><a className="toggle-detail" onClick={e => handleHistoryBack(e)}>{props.t("navigation.back")}</a></span>
			<span><a style={{display: "none"}}>{props.t("navigation.share")}</a></span>
		</div>
		<div className="head">
			<span>
				{props.selectedCategory
					? props.t("navigation.searching.found.withCategory", {fuzzy: searchPhrase, category: props.selectedCategory})
					: props.t("navigation.searching.found.withOutCategory", {fuzzy: searchPhrase})
				}
			</span>
			<Pagination
				currentPageIndex={props.currentPageIndex}
				totalPages={props.totalPages}
				totalItems={props.totalItems}
				itemsPerPage={props.itemsPerPage}
				setPage={props.setPage}
				t={props.t}
			/>
		</div>
		<div id={"category"} className={"swiper"}>
			<Swiper
				onTouchEnd={swiper => {
					detachMouseEvents(swiper);
					swiper.autoplay.stop();
				}}
				modules={[Navigation, Autoplay]}
				className={"swiper-container swiper-list"}
				slidesPerView={1}
				spaceBetween={5}
				autoHeight={true}
				autoPlay={{delay: 9000}}
				loop={products.length > 8}
				loopFillGroupWithBlank={true}
				grabCursor={true}
				loopAdditionalSlides={7}
				navigation={{
					nextEl: ".slider-button-next-1",
					prevEl: ".slider-button-prev-1",
				}}
				autoplay={products.length > 8
					? { delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false }
					: false
				}
				onResize={swiper => {
					document.querySelectorAll(".swiper-list > .swiper-wrapper").forEach(it => {
						it.style.height = "auto";
					});
					swiper.updateAutoHeight();
				}}
			>
				<ProductDoubleSwiperSlider products={products}/>
			</Swiper>
			<div className="swiper-button-next swiper-button-next-tip slider-button-next-1" tabIndex={"0"} role={"button"}
				 aria-label={"Next slide"}/>
			<div className="swiper-button-prev swiper-button-prev-tip slider-button-prev-1" tabIndex={"0"} role={"button"}
				 aria-label={"Next slide"}/>
		</div>
	</div>;
};

export default withTranslation()(SearchPage);
