/* eslint-disable no-throw-literal */
// @flow

import type {Tracking} from "../../models/Tracking";

export default class BaseTracker implements Tracking {
	useEcommerce: boolean;
	trackingId: string;
	eventPrefix: string;

	constructor(trackingId: string, withEcommerce: boolean, eventPrefix = "SELEKT_") {
		this.useEcommerce = withEcommerce;
		this.trackingId = trackingId;
		this.eventPrefix = eventPrefix;
	}

	setEcommerce = value => {
		this.useEcommerce = value;
	};

	handleGenericEvent(cat: string, action: string, label: any): void {
		throw ("not implemented");
	};

	handleOrderEvent(id: string, revenue: number): void {
		throw ("not implemented");
	};

	handleItemEvent(id: string, name: string, articleNumber: string, price: number): void {
		throw ("not implemented");
	};

	logEvent(cat: string, action: string, label: any) {
		this.handleGenericEvent(this.eventPrefix + cat, action, label);
	};

	logOrder(id: string, revenue: number) {
		if (this.useEcommerce) {
			this.handleOrderEvent(id, revenue);
		}
	};

	logItem(id: string, name: string, articleNumber: string, price: number) {
		if (this.useEcommerce) {
			this.handleItemEvent(id, name, articleNumber, price);
		}
	};
}
