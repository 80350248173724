// @flow
import React from "react";
import type Product from "../models/Product";
import "./StartPage.scss";
import type Category from "../models/Category";
import {Swiper} from "swiper/react";
import {Autoplay, Navigation} from "swiper";
import ProductSwiperSlide from "./ProductSwiperSlide";
import CategorySwiperSlide from "./CategorySwiperSlide";
import {Trans, withTranslation} from "react-i18next";
import type {translate} from "../i18n/i18n";
import {getBtnTextColor, getTextColor} from "../helper/setTextColor";
import detachMouseEvents from "../helper/SwiperUtil.cs";

type StartPageNewProps = {
	products: Product[],
	categories: Category[],
	manufacturerTitle: string,
	isIdle: boolean,
	t: translate,
	allProducts: () => void
}

const StartPage = (props: StartPageNewProps) => {
	const filteredCategories = props.categories.filter(cat => cat.imageUrl != null);
	return <div id="teaser" className="sl-startPage sl-page" data-testid={"startPage"}>
		<div className="tip">
			<div className="claim" data-testid={"claim"}>
				{props.manufacturerTitle
					? <span data-testid={"oemHint"}>
						{props.t("main.oemHint", {brand: props.manufacturerTitle})}
					</span>
					: <span>{props.t("main.noBrand")}</span>
				}
			</div>
			<div className={"swiper"}>
				{(props.products && props.products.length > 0)
					? <React.Fragment>
						<Swiper
							onTouchEnd={swiper => {
								detachMouseEvents(swiper);
								swiper.autoplay.stop();
							}}
							modules={[Navigation, Autoplay]}
							className={"swiper-container swiper-tip"}
							slidesPerView={1}
							spaceBetween={5}
							slidesPerGroup={1}
							autoHeight={true}
							loopAdditionalSlides={5}
							loop={true}
							loopFillGroupWithBlank={true}
							grabCursor={true}
							navigation={{
								nextEl: ".swiper-button-next-tip",
								prevEl: ".swiper-button-prev-tip",
							}}
							autoplay={{delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false}}
							onResize={swiper => {
								const node = document.querySelector(".swiper-tip > .swiper-wrapper");
								if (node) node.style.height = "auto";
								swiper.updateAutoHeight();
							}}
						>
							{(props.products.map((product, index) =>
								<ProductSwiperSlide product={product} key={"featured_" + index}/>
							))}
						</Swiper>
						<div className="swiper-button-next swiper-button-next-tip" tabIndex={"0"} role={"button"}
						     aria-label={"Next slide"}/>
						<div className="swiper-button-prev swiper-button-prev-tip" tabIndex={"0"} role={"button"}
						     aria-label={"Next slide"}/>
					</React.Fragment>
					: <div className="outOfOrder" data-testid={"outOfOrder"}>
						<p>
							<Trans
								i18nKey="main.outOfOrder"
								components={[<br className="errorMessageBreak"/>]}
							/>
						</p>
					</div>
				}
			</div>
		</div>
		{
			(props.products && props.products.length > 0) && <React.Fragment>
				<div className="cat">
					{props.categories &&
						<div className={"swiper"}>
							<Swiper
								modules={[Navigation, Autoplay]}
								className={"swiper-container swiper-cat"}
								slidesPerView={1}
								spaceBetween={5}
								slidesPerGroup={1}
								autoHeight={true}
								loopAdditionalSlides={5}
								loop={true}
								loopFillGroupWithBlank={true}
								grabCursor={true}
								navigation={{
									nextEl: ".swiper-button-next-cat",
									prevEl: ".swiper-button-prev-cat",
								}}
								autoplay={{delay: 4500, pauseOnMouseEnter: true, disableOnInteraction: false}}
								onResize={swiper => {
									const node = document.querySelector(".swiper-cat > .swiper-wrapper");
									if (node) node.style.height = "auto";
									swiper.updateAutoHeight();
								}}
							>
								{(filteredCategories.map((category, index) =>
									<CategorySwiperSlide category={category} key={"category_" + index}/>
								))}
							</Swiper>
							<div className="swiper-button-next swiper-button-next-cat" tabIndex={"0"} role={"button"}
							     aria-label={"Next slide"}/>
							<div className="swiper-button-prev swiper-button-prev-cat" tabIndex={"0"} role={"button"}
							     aria-label={"Next slide"}/>
						</div>
					}
				</div>
				<div className="start">
					<span className="txt">
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={getBtnTextColor()}><rect
							fill="none"
							height="24"
							width="24"
							y="0"></rect><path
							d="M7,20h4c0,1.1-0.9,2-2,2S7,21.1,7,20z M5,19h8v-2H5V19z M16.5,9.5c0,3.82-2.66,5.86-3.77,6.5H5.27 C4.16,15.36,1.5,13.32,1.5,9.5C1.5,5.36,4.86,2,9,2S16.5,5.36,16.5,9.5z M14.5,9.5C14.5,6.47,12.03,4,9,4S3.5,6.47,3.5,9.5 c0,2.47,1.49,3.89,2.35,4.5h6.3C13.01,13.39,14.5,11.97,14.5,9.5z M21.37,7.37L20,8l1.37,0.63L22,10l0.63-1.37L24,8l-1.37-0.63L22,6 L21.37,7.37z M19,6l0.94-2.06L22,3l-2.06-0.94L19,0l-0.94,2.06L16,3l2.06,0.94L19,6z"></path></svg>
						<span className={"oem-note"} data-testid={"oem-note"}>
							{props.t("main.findMore", {brand: props.manufacturerTitle})}
						</span>
					</span>
					<span>
						<button className="btn" data-testid={"openAllProducts"}
								style={{color: getTextColor()}} onClick={() => props.allProducts()}>
							{props.t("main.more")}
						</button>
					</span>
				</div>
			</React.Fragment>
		}
	</div>;
};

export default withTranslation()(StartPage);
