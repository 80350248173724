// @flow
import type {Axios} from "axios";
import axios from "axios";
import Configuration from "../Configuration";
import type {ConfigReducerState} from "../reducers/configReducer";
import type PriceRange from "../models/PriceRange";
import type Vehicle from "../models/Vehicle";
import type {SortOrder} from "../models/SortOrder";
import type Product from "../models/Product";
import type {Item} from "../models/Item";
import {SPECIAL_CATEGORY} from "../actions/filter";

export type FilterParameters = {
	categories?: string[],
	categoryKey: string,
	markers?: string[],
	priceRange?: PriceRange,
	vehicleKey: string,
	vehicle: Vehicle,
	productIds?: string[],
	page: number
}

export type ProductFilterQuery = {
	fuzzy?: string,
	sortOrders?: SortOrder,
	filterParameters: FilterParameters,
	categories: any[],
}

export type DealerDetails = {
	"cfgKey": string,
	"identifier": {
		"marketplaceNo": { "index": string },
		"dealerNo": string
	},
	"name": string,
	"address": {
		"street": string,
		"streetNumber": string,
		"town": string,
		"postalCode": string,
		"countryCode": string,
	},
	"dealership": {
		"dealerId": string,
		"distributorName": string,
		"distributorCountry": string,
		"manufacturerLogoUrl": string,
		"manufacturerName": string,
		"manufacturerTitle": string
	},
	"dealerConfiguration": {
		"shopEnabled": boolean,
		"leadModuleEnabled": boolean,
		"productPricesDisabled": boolean
	}
}

export type TrackingProperties = {
	analyticsTrackers: [],
	piwikTracker: [],
	trackerLanguage: string,
	dealerName: string,
	dealerNo: string,
	garageNo: string,
}

export type ConfigurationDetails = {
	currencyCode: string,
	isLeadModuleEnabled: boolean,
	isShopEnabled: boolean,
	languageCode: string,
	productPricesDisabled: boolean,
	theme: string,
	trackingProperties: TrackingProperties,
	watchlistEnabled: boolean,
}

export type LoadConfigResponseType = {
	cfgKey: string,
	dealerId: string,
	name: string,
	filters: {
		vehicleSeries: {}[],
		filterStats: {},
	},
	dealerDetails: DealerDetails,
	garageDealerships: {},
	configurationDetails: {
		headerPositions: [],
		isLeadModuleEnabled: boolean,
		isShopEnabled: boolean,
		watchlistEnabled: boolean,
		productPricesDisabled: boolean,
		languageCode: string,
		configuredCountries: {}[],
		trackingProperties: TrackingProperties,
		theme: string,
	},
	texts: {
		disclaimer: string,
		legalTerms: any,
		mountingNotice: string,
		privacyPolicy: string,
		imprintText: string,
		revocationTerms: string,
	},
	availableLanguages: string[],
};

export const CONFIG_ID_TYPE = {
	DISTRIBUTOR: "DISTRIBUTOR",
	DEALER: "DEALER",
	GARAGE: "GARAGE",
	GARAGE_GROUP: "GARAGE_GROUP",
};
export const INTENT = {
	DEFAULT: "DEFAULT",
	DISTRIBUTOR: "DISTRIBUTOR",
	EXCLUDE_LIFESTYLE: "EXCLUDE_LIFESTYLE",
	VOLVO_SELEKT: "VOLVO_SELEKT",
};

export default class ProductApi {
	api: Axios;

	constructor(configId: string, language: string, intent: string, configIdType: string = CONFIG_ID_TYPE.DEALER) {
		const headers = {
			"Content-Type": "application/json",
			"X-ConfigId": configId,
			"X-ConfigIdType": configIdType,
			"X-Intent": intent,
			"X-ModuleType": "SELEKT",
			"Accept-Language": language + ", *;q0.5",
		};
		this.api = axios.create({
			baseURL: Configuration.value("apiBaseUrl"),
			headers,
		});
	}

	getProducts = (config: ConfigReducerState, filterParameters: ProductFilterQuery, pageSize: ?number) => {
		const {vehicle} = config;
		const {fuzzy, page, categoryKey, vehicleKey} = filterParameters;
		const body = {
			fuzzy,
			filterParameters: {
				categoryKey: SPECIAL_CATEGORY.listAll().includes(categoryKey) ? null : categoryKey,
				vehicleKey,
				vehicle
			},
			sortOrders: [{fieldname: "score", direction: "DESC"}]
		};

		pageSize = Math.min(pageSize || 5000, 100);
		return this.api.post(`/v2/products/search?page=${page}&size=${pageSize}`, body)
			.then(response => {
				return {
					products: response.data.pagedProducts.productItems,
					productCount: response.data.pagedProducts.totalItems,
					totalPages: response.data.pagedProducts.totalPages,
					filterStats: response.data.filterStats,
				};
			});
	};

	getConfig = async (): Promise<LoadConfigResponseType> => {
		return this.api.get("/v2/configure")
			.then(response => response.data);
	};

	getProductDetails = (productId: string, filterParameters: FilterParameters) => {
		const body = {
			filterParameters
		};
		return this.api.post(`/v2/productDetail/${productId}`, body)
			.then(response => response.data);
	};

	validateBookmarks = (dealerId: string, bookmarks: string[] = []): Promise<Product[]> => {
		const defaultPage = 0;
		const defaultPageSize = 1000;

		const productIds = bookmarks;

		const body = {
			dealerId,
			productIds
		};

		return this.api.post(`/v2/products/search?page=${defaultPage}&size=${defaultPageSize}`, body)
			.then(response => response.data.pagedProducts.productItems);
	};

	validateCart = (items: Item[]): Promise<Item[]> => {
		const defaultPage = 0;
		const defaultPageSize = 1000;

		const productIds = items.map(item => item.product.id);

		const body = {
			productIds
		};

		return this.api.post(`/v2/products/search?page=${defaultPage}&size=${defaultPageSize}`, body)
			.then(response => response.data.pagedProducts.productItems);
	};
}
