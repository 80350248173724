// @flow
import React from "react";
import {Provider} from "react-redux";
import store from "./store";
import LangSwitchProvider from "./i18n/LangSwitchProvider";
import ToastifyContainer from "./container/ToastifyContainer";

const Root = ({children}: any) => {
	return (
		<Provider store={store}>
			<LangSwitchProvider>
				<ToastifyContainer/>
				{children}
			</LangSwitchProvider>
		</Provider>
	);
};

export default Root;
