// @flow
import React from "react";
import "./BrowserNotSupported.scss";
import {withTranslation} from "react-i18next";
import type {translate} from "../i18n/i18n";
import i18n from "../i18n/i18n";

export type BrowserNotSupportedProps = {
	t: translate
};

function BrowserNotSupported(props: BrowserNotSupportedProps) {
	i18n.changeLanguage(navigator.language || navigator.userLanguage || "en");

	return <div className="sl-browserNotSupported">
		<div className="sl-notSupportedHeadline">
			{props.t("browserNotSupported.title")}
		</div>

		<div className="sl-notSupportedText">
			{props.t("browserNotSupported.text")}
		</div>

	</div>;
}

export default withTranslation()(BrowserNotSupported);
