import {rootReducer} from "../reducers";
import EventHandler from "../helper/eventHandler";
import {configureStore} from "@reduxjs/toolkit";
import {tracker} from "../helper/tracker";

let store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(tracker)
});

export const updateStore = preloadedState => {
	store = configureStore({
		reducer: rootReducer,
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(tracker),
		preloadedState
	});
	return store;
};
export default store;

export const sessionEventHandler = new EventHandler(store);
