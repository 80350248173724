// @flow
import type {CartReducerState} from "./cartReducer";
import cartReducer from "./cartReducer";
import type {ConfigReducerState} from "./configReducer";
import configReducer from "./configReducer";
import type {ContentReducerState} from "./contentReducer";
import contentReducer from "./contentReducer";
import type {FilterReducerState} from "./filterReducer";
import filterReducer from "./filterReducer";
import type {ConsentReducerState} from "./consentReducer";
import consentReducer from "./consentReducer";

export type ApplicationState = {
	cart: CartReducerState,
	config: ConfigReducerState,
	content: ContentReducerState,
	filter: FilterReducerState,
	consent: ConsentReducerState,
}

export type ReducerAction = {
	type: string,
	payload: any
};

export const rootReducer: ApplicationState = {
	cart: cartReducer,
	config: configReducer,
	content: contentReducer,
	filter: filterReducer,
	consent: consentReducer,
};
