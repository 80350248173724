// @flow

import RootNodeProvider from "./RootNodeProvider";

export type eventType = "CART_CHANGE" | "PRODUCT_COUNT";

export default class EventEmitter {
	emitEvent = (type: eventType, detail: any) => {
		const event = new CustomEvent(type, {detail});
		window.dispatchEvent(event);
		const rootNode = RootNodeProvider.getUclRootNode();
		if (rootNode) rootNode.dispatchEvent(event);
	};
}
