import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../reducers";
import {SwiperSlide} from "swiper/react";
import type {translate} from "../i18n/i18n";
import type Category from "../models/Category";
import {resetPage, setCategory} from "../actions/filter";

export type CategorySwiperSlideProps = {
	category: Category,
	key: string,
	t: translate,
	setCategory: Category => void;
}

const CategorySwiperSlide = (props: CategorySwiperSlideProps) => {
	const {category} = props;

	return <SwiperSlide className="swiper-slide" key={"_" + props.key}>
		<span className="pad cat cat-09" data-testid={"categoryTile"}>
			<span>
				<button onClick={() => props.setCategory(category)} className="img toggle-shop"
						style={{backgroundImage: `url('${category.imageUrl}')`}} data-testid={"openCategory"}
				>
				</button>
				<span className="title">
					<span className="categoryName" data-testid={"categoryTitle"}>
						{category.title}
					</span>
					<span className={"categoryCount"} data-testid={"categoryCount"}>
						{category.count}
					</span>
				</span>
			</span>
		</span>
	</SwiperSlide>;
};

function mapStateToProps(state: ApplicationState, ownProps: CategorySwiperSlideProps) {
	return {
	};
}

function mapDispatchToProps(dispatch, ownProps: CategorySwiperSlideProps) {
	return {
		setCategory: (category: Category) => {
			dispatch(resetPage());
			dispatch(setCategory(category.key));
		}
	};
}

CategorySwiperSlide.displayName = "SwiperSlide";
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CategorySwiperSlide)));
