import {connect} from "react-redux";
import StartPage from "../components/StartPage";
import type {ApplicationState} from "../reducers";
import type {Dispatch} from "redux";
import {setCategory, SPECIAL_CATEGORY} from "../actions/filter";

function mapStateToProps(state: ApplicationState) {
	return {
		products: state.content.topProducts,
		categories: state.filter.filterStats.categories,
		manufacturerTitle: state.config.manufacturerTitle,
	};
}

function mapDispatchToProps(dispatch: Dispatch, state: ApplicationState) {
	return {
		allProducts: () => {
			dispatch(setCategory(SPECIAL_CATEGORY.TOP));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPage);
