import type {FilterParameters, TrackingProperties} from "../api/ProductApi";
import ProductApi from "../api/ProductApi";
import type Product from "../models/Product";
import type {ApplicationState} from "../reducers";
import type {Dispatch} from "redux";
import {setMenu} from "./filter";
import {trackers} from "../helper/tracker";
import GoogleTracker from "../helper/tracker/GoogleTracker";
import PiwikTracker from "../helper/tracker/PiwikTracker";
import CookieStorage from "../helper/storage/CookieStorage";
import EventEmitter from "../helper/event";
import RootNodeProvider from "../helper/RootNodeProvider";
import {sessionEventHandler} from "../store";
import StatisticApi from "../api/StatisticApi";
import {
	productDetailFailureFetched,
	productDetailRequestFetched,
	productDetailSuccessFetched,
	productScopeReset,
	productSet, productsFailureFetched, productsRequestFetched,
	productSuccessFetched,
	topProductsFailureFetched,
	topProductsRequestFetched,
	topProductSuccessFetched
} from "../reducers/contentReducer";
import { configSuccessGet, trackerLoaded } from "../reducers/configReducer";
import {cartSuccessRestored} from "../reducers/cartReducer";
import type Tracker from "../models/Tracker";

export const INIT_MODULE = "INIT_MODULE";
export const GET_CONFIG_REQUEST = "GET_CONFIG_REQUEST";
export const UPDATE_TRACKING = "UPDATE_TRACKING";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_CONFIG_FAILURE = "GET_CONFIG_FAILURE";
export const RESTORE_CART_REQUEST = "RESTORE_CART_REQUEST";


export const setProductScope = (product: Product) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch(productDetailRequestFetched());
		dispatch(setMenu());
		const ProductAPI = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		const categoryKey = (getState().filter.filter.selectedCategory && getState().filter.filter.selectedCategory.key !== "top")
			? getState().filter.filter.selectedCategory.key
			: null;
		const filterParameters: FilterParameters = {
			vehicle: getState().config.vehicle,
			categoryKey,
		};

		ProductAPI.getProductDetails(product.id, filterParameters)
			.then(response => {
				dispatch(productDetailSuccessFetched(response));
			})
			.catch(err => {
				console.error(err);
				dispatch(productDetailFailureFetched(err));
			});
	};
};

export const resetProductScope = () => {
	return (dispatch: Dispatch) => {
		dispatch(productScopeReset());
	};
};

export const restoreCart = () => {
	return async (dispatch: Dispatch, getState: () => ApplicationState) => {
		if (getState().cart.cartDisabled) return;

		dispatch({
			type: RESTORE_CART_REQUEST,
		});

		const initialCart = getState().cart.items;

		let cart = initialCart;
		const {
			dealerid,
			engine = "",
			hsn = "",
			tsn = "",
			model = "",
			series = "",
			fin = "",
			storageid
		} = RootNodeProvider.getUclRootNode().dataset;
		const cartStorage = storageid ? new CookieStorage(storageid) : new CookieStorage(dealerid + hsn + tsn + model + series + engine + fin);

		const cookie = cartStorage.get();
		const rawCart = cookie ? JSON.parse(cookie) : initialCart;

		if (rawCart === initialCart || !rawCart.items.length) {
			return dispatch(cartSuccessRestored(cart));
		}

		const productApi = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		cart = await productApi.validateBookmarks(dealerid, rawCart.items);

		const eventEmitter = new EventEmitter();
		eventEmitter.emitEvent("CART_CHANGE", cart);

		dispatch(cartSuccessRestored(cart));
	};
};

export const fetchConfig = () => {
	return (dispatch, getState: () => ApplicationState) => {
		const productApi = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		dispatch({
			type: GET_CONFIG_REQUEST
		});

		return productApi.getConfig(getState().config.dealerId, getState().config.language, getState().config.intent)
			.then(res => {
				if (!getState().consent.googleAnalytics) {
					sessionEventHandler.requestGoogleAnalyticsConsent();
				} else {
					updateTracker(res.configurationDetails.trackingProperties, dispatch);
				}

				return dispatch(configSuccessGet(res));
			});
	};
};

export const updateTracker = (trackingProperties: TrackingProperties = null, dispatch) => {
		dispatch({
			type: UPDATE_TRACKING
		});
		const analyticTrackers = (trackingProperties || {}).analyticsTrackers || [];
		const googleTrackers = analyticTrackers.filter((tracker: Tracker) => tracker.name === "google");
		const piwikTrackers = analyticTrackers.filter((tracker: Tracker) => tracker.name === "piwik");
    let trackerAdded = false;

		if (googleTrackers && googleTrackers.length > 0) {
      trackerAdded = true;
			trackers.push(
				new GoogleTracker(googleTrackers.map(it => it.trackingKey), false, trackingProperties.dealerNo, trackingProperties.dealerName, trackingProperties.garageNo)
			);
		}
		if (piwikTrackers && piwikTrackers.length > 0) {
      trackerAdded = true;
			const tracker = piwikTrackers[0];
			trackers.push(new PiwikTracker(tracker.trackingKey, tracker.trackingHostUrl, tracker.withEcommerce));
		}

    dispatch(trackerLoaded(trackerAdded));
};

export const getInitialProducts = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		dispatch({
			type: INIT_MODULE,
		});

		dispatch(fetchConfig());
		dispatch(getTopProducts(25));
		dispatch(restoreCart());

		const statisticApi = new StatisticApi(getConfigId(getState()));
		statisticApi.trackModuleLoad();
	};
};

export const getTopProducts = (defaultPageSize: number = 100) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const ProductAPI = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		dispatch(topProductsRequestFetched());

		const config = getState().config;
		const filterParameters = {
			categories: null,
			fuzzy: null,
			page: getState().filter.filter.page
		};

		ProductAPI.getProducts(config, filterParameters, defaultPageSize)
			.then(response => {
				dispatch(topProductSuccessFetched(response));
				const eventEmitter = new EventEmitter();
				eventEmitter.emitEvent("PRODUCT_COUNT", response.productCount);
			})
			.catch(err => dispatch(topProductsFailureFetched(err)));
	};
};

export const getAllProducts = () => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const ProductAPI = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		dispatch(productsRequestFetched());

		const config = getState().config;
		const filterParameters = {
			vehicle: getState().config.vehicle,
			categories: null,
			fuzzy: "",
			page: getState().filter.filter.page
		};

		ProductAPI.getProducts(config, filterParameters, 1000)
			.then(response => {
				dispatch(productSuccessFetched(response));
				const eventEmitter = new EventEmitter();
				eventEmitter.emitEvent("PRODUCT_COUNT", response.productCount);
			})
			.catch(err => dispatch(productsFailureFetched(err)));
	};
};

export const getProducts = (category = null) => {
	return (dispatch: Dispatch, getState: () => ApplicationState) => {
		const ProductAPI = new ProductApi(getState().config.dealerId, getState().config.language, getState().config.intent);
		dispatch(productsRequestFetched());

		const config = getState().config;
		const filterParameters = {
			categoryKey: category || (getState().filter.filter.selectedCategory ? getState().filter.filter.selectedCategory.key : null),
			vehicle: getState().config.vehicle,
			fuzzy: getState().filter.fuzzy,
			page: getState().filter.filter && getState().filter.filter.page ? getState().filter.filter.page : 0
		};

		const defaultPageSize = 100;
		const pageSize = filterParameters.categories || filterParameters.fuzzy ? null : defaultPageSize;

		ProductAPI.getProducts(config, filterParameters, pageSize)
			.then(response => {
				if (response.products.length === 1) {
					dispatch(productSet(response.products[0]));
				} else {
					dispatch(productSuccessFetched(response));
				}
				if (response.productCount > 0) {
					const eventEmitter = new EventEmitter();
					eventEmitter.emitEvent("PRODUCT_COUNT", response.productCount);
				}
			})
			.catch(err => dispatch(productsFailureFetched(err)));
	};
};

const getConfigId = (state: ApplicationState) => {
	try {
		return state.config.dealerId || state.config.dealerDetails.cfgKey || state.config.dealerDetails.identifier.dealerNo;
	} catch (e) {
		return "";
	}
};
