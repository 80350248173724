// @flow

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import cs from "./translations/cs.json";
import da from "./translations/da.json";
import de from "./translations/de.json";
import el from "./translations/el.json";
import en from "./translations/en_gb.json";
import es from "./translations/es.json";
import fr from "./translations/fr.json";
import hu from "./translations/hu.json";
import it from "./translations/it.json";
import nl from "./translations/nl.json";
import no from "./translations/no.json";
import pl from "./translations/pl.json";
import ru from "./translations/ru.json";
import pt from "./translations/pt.json";

const resources = {
	cs: {translations: cs},
	da: {translations: da},
	de: {translations: de},
	el: {translations: el},
	en: {translations: en},
	es: {translations: es},
	fr: {translations: fr},
	hu: {translations: hu},
	it: {translations: it},
	nl: {translations: nl},
	no: {translations: no},
	pl: {translations: pl},
	ru: {translations: ru},
	pt: {translations: pt}
};

export type translate = string => string;

i18n.use(initReactI18next)
	.init({
		resources: resources,
		fallbackLng: "en",
		debug: false,
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
			formatSeparator: ","
		},
		react: {
			useSuspense: true
		}
	});

export default i18n;
