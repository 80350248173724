// @flow
import React from "react";
import "./Loading.scss";
import Spinner from "react-spinkit";

type LoadingProps = {}

const Loading = (props: LoadingProps) => {
	return <div className={"sl-loading"}><Spinner name="ball-beat"/></div>;
};

export default Loading;
