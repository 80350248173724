// @flow

import type Price from "../models/Price";
import {getConfig} from "../reducers/configReducer";
import {hasFractionDigits, hideFractionDigitsForEdge} from "./priceUtil";

const getFormat = (priceValue: number, fractionDigits: string = "", originPrice) => {
	switch (fractionDigits.toLowerCase()) {
		case "2":
			return {
				...{
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}
			};
		default:
			return (hasFractionDigits(priceValue))
				? {
					...{
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					},
				}
				: {
					...{
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					},
					...hideFractionDigitsForEdge(originPrice)
				};
	}
};

const currencyConverter = (price: Price, catalogPrice: Price = price, lang: string = getConfig().language, fractionDigits: string = getConfig().fractionDigits) => {
	return new Intl.NumberFormat(lang, {
		...getFormat((catalogPrice || {}).value, fractionDigits, (price || {}).value),
		...{style: "currency", currency: price.currency}
	}).format(price.value);
};

export default currencyConverter;
