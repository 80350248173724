import React from "react";
import currencyConverter from "../helper/currency";
import {addToCart, removeFromCart} from "../actions/cart";
import {setProductScope} from "../actions/products";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import type {ApplicationState} from "../reducers";
import {hasNoPrice} from "../helper/priceUtil";
import type {translate} from "../i18n/i18n";
import "./ProductSwiperSlider.scss";
import type Product from "../models/Product";
import {getTextColor} from "../helper/setTextColor";
import ShoppingBag from "./icons/ShoppingBag";

export type ProductSlideProps = {
	product: Product,
	cartDisabled: boolean,
	key: string,
	addToCart: string => void,
	removeFromCart: () => void,
	setProductScope: Product => void,
	t: translate,
	className: ?string,
	isSelected: boolean,
}

const ProductSlide = (props: ProductSlideProps) => {
	const [overlay, setOverlay] = React.useState(false);
	const {product, setProductScope, addToCart, removeFromCart} = props;
	const handleAddToCart = () => {
		addToCart(props.t("navigation.maxBookmarksReached"));
		setOverlay(true);
	};
	const handleRemoveFromCart = () => {
		removeFromCart();
		setOverlay(false);
	};

	return <React.Fragment key={"_" + props.key}>
		<span className="pad pro pro-01" data-testid={"category-product-slide"}>
			<span>
				<button onClick={() => setProductScope(product)} data-testid={"openProduct"}
					className={"img toggle-detail " + (overlay ? "plopOverlay" : "")}
					style={{backgroundImage: `url('${product.galleryImage.previewImage
						? product.galleryImage.previewImage.attachmentUrl
						: product.galleryImage.attachmentUrl}')`
					}}
				>
					<span/>
					<span style={{color: getTextColor()}}>{props.t("cart.add")}</span>
				</button>
				<span className="title" data-testid={"productTitle"}>{product.name}</span>
			</span>
			<span>
				<span className="prices">
					{product.recommendedRetailPrice &&
						<span className="crossed_price" data-testid={"retailPrice"}>{currencyConverter(product.recommendedRetailPrice)}</span>}
					{product.price && (
						<div>
							<span className="price" data-testid={"price"}>
								{currencyConverter(product.price)}
							</span>
							{product.unitPrice && (
								<span className="unit-price">
									<small>
										({currencyConverter(product.unitPrice.pricePerUnit)} / {product.unitPrice.measuringUnit === "sqm" ? "m²" : product.unitPrice.measuringUnit === "cbm" ? "m³" : product.unitPrice.measuringUnit})
									</small>
								</span>
							)}
						</div>
					)}
				</span>
				<span className="links">
					<button className="toggle-shop toggle-detail" onClick={() => setProductScope(product)} data-testid={"productDetails"}>
						{props.t("navigation.details")}
					</button>
					{!props.cartDisabled && (props.isSelected
						? <button className={"add toggle-card-add-01 productIsSelected"}
						          onClick={() => handleRemoveFromCart()} data-testid={"removeProduct"}
						>
							{props.t("navigation.remove")}
							<ShoppingBag className={"shoppingBagafter"} fill={getTextColor()}/>
						</button>
						: <button className={"add toggle-card-add-01"} onClick={() => handleAddToCart()} data-testid={"addProduct"}>
							{props.t("navigation.add")}
							<ShoppingBag className={"shoppingBagbefore"} fill={"black"}/>
						</button>)
					}
				</span>
			</span>
		</span>
	</React.Fragment>;
};

function mapStateToProps(state: ApplicationState, ownProps: ProductSwiperSlideProps) {
	return {
		cartDisabled: state.cart.cartDisabled,
		hidePrices: state.cart.hidePrices || hasNoPrice(ownProps.product),
		isSelected: state.cart.items.some(item => item.id === ownProps.product.id)
	};
}

function mapDispatchToProps(dispatch, ownProps: ProductSwiperSlideProps) {
	return {
		addToCart: (errorMsg: string) => dispatch(addToCart(ownProps.product, errorMsg)),
		removeFromCart: () => dispatch(removeFromCart(ownProps.product)),
		setProductScope: product => {
			dispatch(setProductScope(product));
			ownProps.history.push(`/product/${product.id}`);
		}
	};
}

ProductSlide.displayName = "SwiperSlide";
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductSlide)));
