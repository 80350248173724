// @flow
import {connect} from "react-redux";
import type {ApplicationState} from "../reducers";
import {getInitialProducts} from "../actions/products";
import App from "../components/App";
import {Dispatch} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state: ApplicationState) {
	return {
		isIdle: state.content.isIdle,
		theme: state.config.intent,
		disclaimer: state.content.disclaimer,
		manufacturerTitle: state.config.manufacturerTitle,
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		getProducts: () => dispatch(getInitialProducts())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
