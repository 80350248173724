import type Product from "../models/Product";

export const hasNoPrice = (product: Product): boolean => {
	const {price, recommendedRetailPrice, unitPrice, distributionChannels} = product;
	const {shipping, mounting, pickup} = distributionChannels;
	return !(price || recommendedRetailPrice || unitPrice || shipping || mounting || pickup);
};

export const hideFractionDigitsForEdge = (priceValue: number) => {
	const significantPriceDigits = Math.round(priceValue).toString().length;
	return {maximumSignificantDigits: significantPriceDigits};
};

export const hasFractionDigits = (priceValue: number): boolean => {
	return priceValue % 1 !== 0;
};
